export default [
  {
    path: "/banners/main-page/",
    name: "banners-main",
    meta: {
      title: "Баннеры",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/banners/views/banners.vue"),
  },
  {
    path: "/banners/main-page/:id",
    name: "banner-main-edit",
    meta: {
      title: "Баннер",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/banners/views/banner-edit.vue"),
  },
  {
    path: "/banners/main-page/new",
    name: "banner-main-create",
    meta: {
      title: "Баннер",
      authRequired: true,
    },
    component: () => import("@/modules/catalog/banners/views/banner-edit.vue"),
  },
];
